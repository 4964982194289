import {Controller} from "stimulus";
import DromoUploader from "dromo-uploader-js";
import {navigator} from "@hotwired/turbo";
import {createInput} from "src/common/form";
import * as chrono from "chrono-node";

export default class DromoController extends Controller<HTMLElement> {
  dromo: DromoUploader;

  static targets = ["file"];

  static values = {
    frontendToken: String,
    userId: String,
    isDevelopment: Boolean,
  };

  declare frontendTokenValue: string;
  declare userIdValue: string;
  declare isDevelopmentValue: boolean;
  declare fileTarget: HTMLInputElement;

  submit(e: Event) {
    const button = e.target as HTMLButtonElement;
    const form = button.closest("form")!;

    if (!form.checkValidity()) {
      e.preventDefault();
      return;
    }

    document.documentElement.dispatchEvent(new CustomEvent("tlw-modal:trigger-close", {bubbles: true}));

    this.showProgressBar();

    if (button) {
      button.disabled = true;
    }

    this.dromo = new DromoUploader(
      this.frontendTokenValue,
      this.template,
      this.settings,
      this.user,
    );

    this.dromo.onCancel(() => {
      if (button) {
        button.disabled = false;
      }

      this.hideProgressBar();
    });

    const templateKeys = new Set(this.template.map((field) => field.key));

    this.datetimeFields
      .filter((key) => templateKeys.has(key))
      .forEach((key) => {
        this.dromo.registerBulkRowHook(this.datetimeParser(key));
      });

    this.dateFields
      .filter((key) => templateKeys.has(key))
      .forEach((key) => {
        this.dromo.registerBulkRowHook(this.dateParser(key));
      });

    this.timeFields
      .filter((key) => templateKeys.has(key))
      .forEach((key) => {
        this.dromo.registerBulkRowHook(this.timeParser(key));
      });

    this.dromo.onResults((rows, metadata) => {
      Object.keys(metadata.fields).forEach(header => {
        form.appendChild(createInput("datahub_import[headers][]", header));
      });

      if (this.bouncePathElement) {
        form.appendChild(createInput("bounce_path", this.bouncePathElement.value));
      }

      rows.forEach(row => {
        row.dromo_valid_row = true;
        row.dromo_error_message = "";
      });

      metadata.errors.forEach(error => {
        const row = rows[error.rowIndex];

        if (row) {
          row.dromo_valid_row = false;
          row.dromo_error_message = error.message;
        }
      });

      const blob = new Blob([JSON.stringify(rows)], {type: "application/json"});
      const parsedFile = new File([blob], "parsed.json", {type: "application/json"});
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(parsedFile);

      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.name = "datahub_import[parsed_file]";
      fileInput.files = dataTransfer.files;

      form.appendChild(fileInput);
      form.submit();
    });

    this.dromo.open();
  }

  datetimeParser = (key) => (records, _metadata) => {
    return records.map((record) => {
      if (!record.row[key]?.value) return record;

      const datetime = record.row[key].value;
      const parsedDatetime = chrono.en.GB.parseDate(datetime)?.toLocaleString();

      if (datetime === parsedDatetime) return record;

      if (parsedDatetime) {
        record.row[key].value = parsedDatetime;
        record.row[key].info = [
          {
            message: `Parsed from ${datetime} to ${parsedDatetime}`,
            level: "info",
          },
        ];
      } else {
        record.row[key].info = [
          {
            message: `Error parsing ${record.row[key].value}`,
            level: "error",
          },
        ];
      }

      return record;
    });
  };

  dateParser = (key) => (records, _metadata) => {
    return records.map((record) => {
      if (!record.row[key]?.value) return record;

      const date = record.row[key].value;
      const parsedDate = chrono.en.GB.parseDate(date)?.toLocaleDateString();

      if (date === parsedDate) return record;

      if (parsedDate) {
        record.row[key].value = parsedDate;
        record.row[key].info = [
          {
            message: `Parsed from ${date} to ${parsedDate}`,
            level: "info",
          },
        ];
      } else {
        record.row[key].info = [
          {
            message: `Error parsing ${record.row[key].value}`,
            level: "error",
          },
        ];
      }

      return record;
    });
  };

  timeParser = (key) => (records, _metadata) => {
    return records.map((record) => {
      if (!record.row[key]?.value) return record;

      const time = record.row[key].value;
      const splitTimeString = time.split(":");
      const sanitisedTime = `${splitTimeString[0]}:${splitTimeString[1]}`;

      if (time === sanitisedTime) return record;

      record.row[key].value = sanitisedTime;
      record.row[key].info = [
        {
          message: `Sanitised from ${time} to ${sanitisedTime}`,
          level: "info",
        },
      ];

      return record;
    });
  };

  private showProgressBar = () => {
    navigator.delegate.adapter.progressBar.setValue(0);
    navigator.delegate.adapter.progressBar.show();
  };

  private hideProgressBar = () => {
    navigator.delegate.adapter.progressBar.setValue(1);
    navigator.delegate.adapter.progressBar.hide();
  };

  private get settings(): any {
    return {
      initialFile: this.initialFile,
      matchingStep: {
        headerRowOverride: 0
      },
      autoMapHeaders: true,
      styleOverrides: {
        global: {
          primaryTextColor: "#333570"
        },
        primaryButton: {
          textColor: "#ffffff",
          backgroundColor: "#0034E5"
        }
      },
      backendSyncMode: "MAPPINGS_ONLY",
      allowCustomFields: false,
      invalidDataBehavior: "INCLUDE_INVALID_ROWS_AND_VALUES",
      importIdentifier: this.entity,
      developmentMode: this.isDevelopmentValue,
      highlightAutoFixes: true,
    };
  };

  private get user() {
    return {
      id: this.userIdValue,
    };
  }

  private get bouncePathElement() {
    return document.getElementById("bounce_path") as HTMLInputElement;
  }

  private get entity() {
    const element = document.getElementById("datahub_import_entity") as HTMLInputElement;
    return element.value;
  }

  private get template() {
    const element = document.getElementById("dromo_template") as HTMLInputElement;
    if (!element) return {};

    try {
      const template = JSON.parse(element.value || "{}");

      if (template.validators) {
        template.validators.forEach((validator) => {
          if (validator.regex) {
            validator.regex = new RegExp(validator.regex);
          }
        });
      }

      return template;
    } catch (_e) {
      return {};
    }
  }

  private get initialFile() {
    if (this.fileTarget && this.fileTarget.files) {
      return this.fileTarget.files[0];
    }
    return null;
  }

  private get datetimeFields() {
    return [
      "projected_start_date_and_time",
      "projected_end_date_and_time",
      "actual_start_date_and_time",
      "actual_end_date_and_time",
    ];
  }

  private get dateFields() {
    return [
      "date_of_birth",
      "signed_up_on",
      "commenced_on",
      "next_review_on",
      "hcp_exit_date",
    ];
  }

  private get timeFields(): string[] {
    return [
      "applicable_start_time",
      "applicable_end_time",
    ];
  }
}
