import c0 from './dromo_controller';
import c1 from './flash_controller';
import c2 from './map_controller';
import c3 from './with_floating/positioner_controller';
import c4 from './with_floating/visibility_controller';
export const definitions = [
	{identifier: 'dromo', controllerConstructor: c0},
	{identifier: 'flash', controllerConstructor: c1},
	{identifier: 'map', controllerConstructor: c2},
	{identifier: 'with-floating--positioner', controllerConstructor: c3},
	{identifier: 'with-floating--visibility', controllerConstructor: c4},
];
