export const createForm = (action: string, data: FormData, turbo = true) => {
  const formElement = document.createElement("form");

  formElement.method = "post";
  formElement.action = action;

  if (turbo) {
    formElement.dataset.turbo = "true";
    formElement.dataset.turboStream = "true";
  }

  formElement.appendChild(createInput("_method", "patch"));
  formElement.appendChild(createInput("authenticity_token", csrfToken()));
  formElement.appendChild(createInput("bounce_path", window.location.href));

  const formDataObject = Object.fromEntries(data as any);

  Object.entries(formDataObject).forEach(([key, value]) => {
    formElement.appendChild(createInput(key, value));
  });

  formElement.style.opacity = "0";
  formElement.style.position = "absolute";
  document.body.appendChild(formElement);

  return formElement;
};

export const createInput = (name: string, value: string) => {
  const input = document.createElement("input");

  input.name = name;
  input.value = value;
  input.type = "hidden";

  return input;
};

const csrfToken = () => {
  const meta = document.querySelector("meta[name=csrf-token]");
  return meta?.getAttribute("content") as string;
};

export const autoResizeSelect = (select: HTMLSelectElement) => {
  const selectedOption = select.options[select.selectedIndex];
  const placeholder = document.createElement("div");
  const selectStyles = window.getComputedStyle(select);

  ["font-weight", "font-size", "font-family", "padding"].forEach(property =>
    placeholder.style.setProperty(property, selectStyles.getPropertyValue(property))
  );
  placeholder.style.setProperty("opacity", "0");
  placeholder.style.setProperty("position", "fixed");
  placeholder.innerText = selectedOption.text;
  document.body.append(placeholder);

  select.style.setProperty("width", placeholder.getBoundingClientRect().width + "px");

  placeholder.remove();
};
